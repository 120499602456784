<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span>
            商务名称：
            <el-autocomplete
              class="ipt_width project_width"
              v-model="parameter.businessName"
              :fetch-suggestions="businessNameQuerySearch"
              placeholder="请输入或选择商务名称"
              @select="onProkectNameSelect"
              :popper-append-to-body="false"
            >
            </el-autocomplete>
          </span>
          <span>
            商务类型：
            <!-- <Dictionary
              v-model="parameter.businessTypeList"
              class="ipt_width"
              code="BUSINESS_TYPE"
              multiple
              multipleType="array"
              placeholder="请选择商务类型"
            /> -->
            <el-select
              class="ipt_width tags-select-input"
              clearable
              v-model="parameter.businessTypeList"
              filterable
              placeholder="请选择商务类型"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in dictData.businessType"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option>
            </el-select>
          </span>
          <span>
            商务状态：
            <!-- <Dictionary
              v-model="parameter.statusList"
              class="ipt_width"
              multiple
              multipleType="array"
              code="ALL_BUSINESSMANAGE_TYPE"
              placeholder="请选择商务状态"
            /> -->
            <el-select
              class="ipt_width tags-select-input"
              clearable
              v-model="parameter.statusList"
              filterable
              placeholder="请选择商务状态"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in dictData.status"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option>
            </el-select>
          </span>
          <span>
            商务负责人：
            <el-select
              class="ipt_width tags-select-input"
              clearable
              v-model="parameter.principalUserIdList"
              filterable
              placeholder="请输入或者选择"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in selectedList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span>
            业主单位：
            <!-- ownerUnit -->
            <!-- <el-input
              class="ipt_width"
              v-model="parameter.ownerUnit"
              clearable
              placeholder="请输入"
            ></el-input> -->
            <el-select
              class="ipt_width tags-select-input"
              clearable
              v-model="parameter.ownerUnitList"
              filterable
              multiple
              collapse-tags
              placeholder="请输入或者选择"
            >
              <el-option
                v-for="item in ownerUnitList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </span>
          <span>
            填报人：
            <!-- <el-input
              class="ipt_width"
              v-model="parameter.initiatorId"
              clearable
              placeholder="请输入"
            ></el-input> -->
            <el-select
              class="ipt_width"
              clearable
              v-model="parameter.initiatorId"
              filterable
              placeholder="请输入或者选择"
            >
              <el-option
                v-for="item in initiatorUserList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <div class="search_center">
            <span
              >项目来源：
              <el-select
                class="ipt_width tags-select-input"
                v-model="parameter.projectSourceList"
                placeholder="请选择项目来源"
                filterable
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in dictData.projectSource"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :key="item.dictVal"
                ></el-option>
              </el-select>
            </span>
          </div>
          <div class="search_center">
            <span>商务追踪时间：</span>
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
          <el-button
            type="primary"
            icon="el-icon-printer"
            v-if="permission(['ALL_BUSINESS_EXPORT'])"
            @click="exporting()"
            >导出</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <div class="colorExplain">
            状态颜色说明：
            <span>审批不通过 <i style="background: #e8ebc0"></i></span>
            <span>已移交计划经营部 <i style="background: #d4f5fd"></i></span>
            <span>已创建项目 <i style="background: #d3dffc"></i></span>
            <!-- <span>项目完结 <i style="background: #c4fcc7"></i></span> -->
            <span>商务暂停 <i style="background: #cde7f7"></i></span>
            <span>商务终结 <i style="background: #e08477c0"></i></span>
          </div>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            :row-class-name="tableRowClassName"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              min-width="320"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <el-table-column
              align="center"
              min-width="220"
              :show-overflow-tooltip="false"
              prop="ownerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contacts"
              label="经办人"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contactsPhone"
              label="经办人电话"
              width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="initiatorName"
              label="填报人"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="实际商务负责人"
              sortable="custom"
              width="150"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="createdDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="businessStartDate"
              label="商务实际开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.businessStartDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="businessType"
              sortable="custom"
              label="商务类型"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              sortable="custom"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">暂存</span>
                <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
                <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
                <span v-if="scope.row.status == 5">已移交计划经营部</span>
                <span v-if="scope.row.status == 6">已创建项目</span>
                <!-- <span v-if="scope.row.status == 7">项目完结</span> -->
                <!-- <span v-if="scope.row.status == 8">商务失败</span> -->
                <span v-if="scope.row.status == 9">商务暂停</span>
                <span v-if="scope.row.status == 10">商务终结</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" fixed="right" label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Edit_Bgc"
                  size="small"
                  v-if="scope.row.status != 0 && scope.row.status != 5 && scope.row.status != 10"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
                <el-popover placement="bottom" width="230" trigger="hover">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Remove_Bgc"
                    v-if="permission(['SWXX_DELETE'])"
                    @click.stop="delFn(scope.row.id)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    class="text_RESET_PASSWORD_Bgc"
                    size="small"
                    @click="cost(scope.row)"
                    >费用</el-button
                  >
                  <el-button
                    type="text"
                    class="text_Miantan_Bgc"
                    size="small"
                    v-if="
                      scope.row.operatingButton &&
                      scope.row.operatingButton.some(r => {
                        return r == 'VIEW_PERFORMANCE'
                      }) &&
                      permission(['VIEW_SETTLE_ACCOUNT'])
                    "
                    @click="computation(scope.row)"
                    >结算</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    v-if="
                      scope.row.operatingButton &&
                      scope.row.operatingButton.some(r => {
                        return r == 'VIEW_PERFORMANCE'
                      }) &&
                      permission(['VIEW_BUSINESS_DISPOSABLE_EXPENSES'])
                    "
                    class="text_Edit_Bgc"
                    @click.stop="isBusinessCostFn(scope.row)"
                    >结算调整</el-button
                  >

                  <span slot="reference" class="more">更多</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <ManageView
      v-if="showEditDialog"
      :isShow.sync="showEditDialog"
      :isSubmit="false"
      :options="form"
    ></ManageView>
    <EditManage
      v-if="showEditDialog2"
      :isShow.sync="showEditDialog2"
      :options="form"
      :edit_flag="true"
    ></EditManage>

    <!-- 点击费用弹出支出信息部分 -->
    <EditDialog width="90%" :isShow.sync="costDialog_show" id="costDialog">
      <div slot="title" class="title">
        <el-row>
          <el-col :span="12"><div>商务记账信息</div></el-col>
          <el-col :span="12"> <div>员工记账明细</div></el-col>
        </el-row>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-row>
          <el-col :span="12">
            <el-card>
              <div class="cost-dialog-title">
                <el-descriptions border size="medium">
                  <el-descriptions-item label="商务名称" labelStyle="min-width: 90px;">{{
                    costDialog_data.businessName
                  }}</el-descriptions-item>
                  <el-descriptions-item
                    label="商务负责人"
                    labelStyle="min-width: 100px;"
                    contentStyle="min-width: 90px;"
                    >{{ costDialog_data.principalUserName }}</el-descriptions-item
                  >
                </el-descriptions>
              </div>
              <el-table
                :data="costDialog_data.leftTableData"
                border
                ref="leftTableRef"
                height="350"
                style="width: 100%"
                v-loading="leftCostLoading"
                :highlight-current-row="false"
                :cell-class-name="setCellClass"
              >
                <el-table-column
                  type="index"
                  align="center"
                  label="序号"
                  width="70"
                  :show-overflow-tooltip="false"
                >
                </el-table-column>
                <el-table-column prop="targetName" width="100" align="center" label="姓名">
                </el-table-column>
                <el-table-column prop="role" width="150" align="center" label="角色">
                  <template slot-scope="scope">
                    {{ scope.row.role | dict(dictData.businessStaffRole) }}
                  </template>
                </el-table-column>
                <el-table-column prop="sumCost" align="center" label="总报销金额">
                  <template slot-scope="scope">
                    {{ scope.row.sumCost | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column prop="sumTallyCost" align="center" label="总记账金额">
                  <template slot-scope="scope">
                    {{ scope.row.sumTallyCost | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column width="100" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="selectUserCost(scope.row, scope.$index)"
                      >明细</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: right; margin-top: 10px">
                <el-pagination
                  @size-change="handleLeftSizeChange"
                  @current-change="handleLeftCurrentChange"
                  :current-page="leftParams.pageNow"
                  :page-sizes="[5, 10, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="leftParams.total"
                >
                </el-pagination>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card v-loading="rightCostLoading">
              <div v-if="costDialog_data.selectId">
                <div class="cost-dialog-title">
                  <el-descriptions border :column="2" size="medium">
                    <el-descriptions-item
                      label="成员"
                      :span="1"
                      :content-class-name="[this.highlightedCell ? 'highlight' : '']"
                    >
                      {{ costDialog_data.selectName }}</el-descriptions-item
                    >
                    <el-descriptions-item label="角色" :span="1">
                      {{
                        costDialog_data.selectRole | dict(dictData.businessStaffRole)
                      }}</el-descriptions-item
                    >
                  </el-descriptions>
                </div>
                <el-table
                  :data="costDialog_data.rightTableData"
                  border
                  height="350"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    align="center"
                    label="序号"
                    width="70"
                    :show-overflow-tooltip="false"
                  >
                  </el-table-column>
                  <el-table-column prop="date" align="center" label="记账时间">
                    <template slot-scope="scope">
                      {{ scope.row.createdDate | dateFormat }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="moduleType"
                    min-width="120"
                    align="center"
                    label="记账类型"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.moduleType | dict(dictData.moduleType) }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="sumCost" align="center" label="报销金额">
                    <template slot-scope="scope">
                      {{ scope.row.sumCost | applyAmount }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="costTally" align="center" label="记账金额">
                    <template slot-scope="scope">
                      {{ scope.row.costTally | applyAmount }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="staffBusinessPerformance"
                    width="120"
                    align="center"
                    label="操作"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="showCostDetail(scope.row)"
                        :loading="rightDetailLoading"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 10px">
                  <el-pagination
                    @size-change="handleRightSizeChange"
                    @current-change="handleRightCurrentChange"
                    :current-page="rightParams.pageNow"
                    :page-sizes="[5, 10, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="rightParams.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="empty-detail" v-else><span class="content">点击左侧明细</span></div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeDialog">返回</el-button>
      </div>
    </EditDialog>

    <BusinessPerformanceDetailDialog
      v-if="costDetailDialog_show"
      :costDetailDialog_show="costDetailDialog_show"
      :costDetail="costDialog_data.costDetail"
      :type="costDialog_data.moduleType"
      :imgList="imgList"
      @closeDialog="handleCloseCostDetail"
    ></BusinessPerformanceDetailDialog>

    <!-- 点击结算弹出商务绩效核算中的费用结算部分 -->
    <EditDialog
      width="70%"
      @closeFn="isComputationShow = false"
      :isReturn="true"
      v-if="isComputationShow"
      :isShow.sync="isComputationShow"
    >
      <div slot="title" class="title">商务费用结算</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-descriptions
          style="margin-top: 10px; margin-bottom: 10px"
          title="商务基础信息"
          direction="vertical"
          :column="5"
          border
        >
          <el-descriptions-item label="商务名称" :span="2">
            {{ computation_data.businessName || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="商务类型" :span="1">
            {{ computation_data.businessType | dict(dictData.businessType) }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ computation_data.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ computation_data.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>

          <el-descriptions-item label="业主单位" :span="3">
            {{ computation_data.ownerUnit || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="项目承接公司" :span="1">
            {{ computation_data.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item label="实际商务负责人" :span="1">
            {{ computation_data.principalUserName | addDept(CompanyMembers) }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          style="margin-top: 10px; margin-bottom: 10px"
          title="商务费用结算"
          direction="vertical"
          :column="5"
          border
        >
          <template slot="extra">
            <span style="font-size: 12px; color: gray">单位：元</span>
          </template>
          <el-descriptions-item
            label="我方合同金额"
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            {{ computation_data.newData.ourContractAmount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              税费总计
              <span style="font-size: 12px; color: red; padding-left: 20px">
                包含增值税、附加税、印花税
              </span>
            </template>
            {{ computation_data.newData.taxesFee | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="招投标费用"
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            {{ computation_data.newData.tenderFee | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="预计外部协作资金"
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            {{ computation_data.newData.exteriorBusinessCosts | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="实际外部协作资金"
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            {{ computation_data.newData.actualExteriorBusinessCosts | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="2"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              预计可支配金额
              <span style="font-size: 12px; color: red; padding-left: 20px">
                预计可支配金额 = 我方合同金额 - 税费 - 招投标费用 - 预计外部协作资金
              </span>
            </template>
            {{ computation_data.newData.predictDisposableProjectExpense | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label"> 到款金额 </template>
            {{ computation_data.newData.receivedAmount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              <div>
                内部生产资金
                <el-button
                  style="margin-left: 20px"
                  type="primary"
                  size="mini"
                  @click="showNBSCFY"
                  :loading="isNBSCFYButtonLoading"
                  >查看比例</el-button
                >
              </div>
              <div style="margin-top: 5px">
                <span style="font-size: 12px; color: red">
                  内部生产资金 = 项目可支配资金 X 生产费用比例
                </span>
              </div>
            </template>
            {{ computation_data.newData.productionCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              <div>
                外协生产资金
                <el-button
                  style="margin-left: 20px"
                  type="primary"
                  size="mini"
                  @click="showWXSCFY"
                  :loading="isWXSCFYButtonLoading"
                  >查看详情</el-button
                >
              </div>
              <div style="margin-top: 5px; opacity: 0%">
                <span style="font-size: 12px; color: red"> 外协生产资金 </span>
              </div></template
            >
            {{ computation_data.newData.projectOutsourceByBusiness | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="3"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              结算可支配资金
              <span style="font-size: 12px; color: red; padding-left: 20px">
                结算可支配资金 = （预计可支配金额 - 内部生产资金 - 外协生产资金）X 25%
              </span>
            </template>
            {{ computation_data.newData.interiorBusinessPerformance | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="my-label"
            content-class-name="my-content"
          >
            <template slot="label">
              <div>商务部管理资金</div>
              <div style="margin-top: 5px">
                <span style="font-size: 12px; color: red">
                  商务部管理资金 = 结算可支配资金 X 20%
                </span>
              </div>
            </template>
            {{ computation_data.newData.businessDeptPerformance | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label-class-name="my-label projectBGC"
            content-class-name="my-content projectBGC"
            :span="1"
          >
            <template slot="label"> 实际绩效金额 </template>
            {{ computation_data.newData.actualPerformance | applyAmount }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="isComputationShow = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 查看内部生产资金比例 -->
    <EditDialog width="60%" :isShow.sync="NBSCFY_ratio_show">
      <div slot="title" class="title">内部生产资金比例</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          当前生产费用比例 ：
          <span style="color: red">{{ NBSCFY_ratio_data.productionCostSumRatio * 100 }}%</span>
        </div>
        <div class="below-content">
          <div style="width: 50%">
            <el-table
              :data="NBSCFY_ratio_data.productionCostRatioLogByProjectList"
              style="width: 100%"
              border
              row-key="projectId"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="props">
                  <div
                    style="
                      font-size: 14px;
                      color: black;
                      font-weight: 700;
                      margin-bottom: 5px;
                      margin-left: 20px;
                      margin-top: 10px;
                    "
                  >
                    历史记录：
                  </div>
                  <el-table
                    :data="props.row.productionCostRatioLogList"
                    border
                    style="width: 92%; margin-bottom: 10px; margin-left: 20px"
                  >
                    <el-table-column
                      type="index"
                      align="center"
                      width="30"
                      :show-overflow-tooltip="false"
                    ></el-table-column>
                    <el-table-column
                      label="业务种类"
                      prop="businessLines"
                      align="center"
                      width="105"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.businessLines | dict(dictData.businessLines) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="项目来源"
                      prop="projectSource"
                      align="center"
                      width="85"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.projectSource | dict(dictData.projectSource) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="productionCostRatio"
                      label="比例"
                      width="70"
                      align="center"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.productionCostRatio * 100 }}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="createdDate" label="更改日期" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.createdDate | dateFormat }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <!-- <el-table-column label="序号" prop="id" align="center" width="40"> </el-table-column> -->
              <el-table-column
                label="项目名称"
                prop="projectName"
                align="center"
                :show-overflow-tooltip="false"
              >
              </el-table-column>
              <el-table-column
                label="生产费用占比"
                prop="productionCostRatioByProject"
                align="center"
                width="80"
                class-name="height-line"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.productionCostRatioByProject * 100 }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 0 0 0 5px; flex: 1">
            <img src="@/assets/简化生产费用比例.png" alt="生产费用比例说明" width="96%" />
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="NBSCFY_ratio_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 查看项目外协费用 -->
    <EditDialog width="60%" :isShow.sync="WXSCFY_detail_show">
      <div slot="title" class="title">外协生产资金</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          目前外协生产总费用 ：
          <span style="color: red">{{ WXSCFY_detail_data.outsourceSumCost }}元</span>
        </div>
        <el-table
          :data="WXSCFY_detail_data.projectOutsourceLogByProjectList"
          style="width: 100%"
          border
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <div
                style="
                  font-size: 14px;
                  color: black;
                  font-weight: 700;
                  margin-bottom: 5px;
                  margin-left: 20px;
                  margin-top: 10px;
                "
              >
                外协单位信息：
              </div>
              <el-table
                :data="props.row.projectOutsourceLogByName"
                border
                style="width: 96%; margin-bottom: 10px; margin-left: 20px"
              >
                <el-table-column type="expand" width="30">
                  <template slot-scope="props">
                    <div
                      style="
                        font-size: 14px;
                        color: black;
                        font-weight: 700;
                        margin-bottom: 5px;
                        margin-left: 20px;
                        margin-top: 10px;
                      "
                    >
                      历史记录：
                    </div>
                    <el-table
                      :data="props.row.projectOutsourceLogRespList"
                      border
                      style="width: 96%; margin-bottom: 10px; margin-left: 20px"
                    >
                      <el-table-column
                        label="序号"
                        type="index"
                        align="center"
                        width="100"
                        :show-overflow-tooltip="false"
                      ></el-table-column>
                      <el-table-column prop="cost" label="外协生产资金" align="center" width="180">
                        <template slot-scope="scope"> {{ scope.row.outsourceCost }}元 </template>
                      </el-table-column>
                      <el-table-column prop="date" label="更改日期" align="center">
                        <template slot-scope="scope">
                          {{ scope.row.createdDate | dateFormat }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="outsourceName" label="外协单位" align="center">
                </el-table-column>
                <el-table-column
                  prop="outsourceSumCostByName"
                  label="外协生产资金"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope"> {{ scope.row.outsourceSumCostByName }}元 </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!-- <el-table-column label="序号" prop="id" align="center" width="100"> </el-table-column> -->
          <el-table-column label="项目名称" prop="projectName" align="center"> </el-table-column>
          <el-table-column label="业务种类" prop="businessLines" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.businessLines | dict(dictData.businessLines) }}
            </template>
          </el-table-column>
          <el-table-column label="项目来源" prop="projectSource" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.projectSource | dict(dictData.projectSource) }}
            </template>
          </el-table-column>
          <el-table-column
            label="项目外协生产资金合计"
            prop="outsourceSumCostByProject"
            align="center"
            width="170"
            class-name="height-line"
          >
            <template slot-scope="scope"> {{ scope.row.outsourceSumCostByProject }}元 </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="WXSCFY_detail_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 结算调整 -->
    <BusinessPerformanceAdjustment
      :options="businessCostInfo"
      v-if="isBusinessCostShow"
      :isBusinessCostShow.sync="isBusinessCostShow"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditManage: () => import('@/views/publicView/update/Edit_Manage.vue'),
    ManageView: () => import('@/views/publicView/ManageView.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    BusinessPerformanceDetailDialog: () =>
      import('@/components/BusinessPerformanceDetailDialog.vue'),
    BusinessPerformanceAdjustment: () => import('./business-performance-adjustment.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null,
        businessTypeList: null,
        statusList: null,
        order: 'created_date',
        orderRule: 'DESC',
        principalUserIdList: null,
        projectSourceList: null,
        beginDate: null,
        endDate: null,
      },
      pageSize: 0,
      condition: {
        title: '',
      },
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showEditDialog2: false,
      dictData: {
        businessType: [],
      },
      businessList: [],
      selectedList: [],

      dictData: {
        moduleType: [],
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
        companyType: [],
        businessLines: [],
        businessStaffRole: [],
        status: [],
      },

      leftParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: null,
      }, //报销信息左侧弹框的请求参数
      rightParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: null,
        targetId: null,
      }, //dialogVisible
      costDialog_data: {
        moduleType: null,
        businessName: null,
        businessManageId: null,
        principalUserName: null,
        principalUserId: null,
        selectName: null, //点击左侧明细后右侧显示姓名
        selectRole: null, //点击左侧明细后右侧显示角色
        selectId: null, //点击左侧明细后右侧显示id
        // 左侧商务报销信息（按员工划分
        leftTableData: [],
        rightTableData: [], //右侧员工明细
        costDetail: {},
      },
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false,

      isCostDialogLoading: false, //报销详情button按钮的loading
      leftCostLoading: false, //弹框左侧表格loading
      rightCostLoading: false, //弹框右侧表格loading
      rightDetailLoading: false,

      img_index: 0, //附件图片预览下标
      imgList: [], //附件图片数组
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      highlightedCell: null,

      // 商务费用结算弹框部分
      isComputationShow: false, //商务费用结算弹框显示与隐藏
      computation_data: {},

      NBSCFY_ratio_show: false, // 内部生产资金弹框显示隐藏
      isNBSCFYButtonLoading: false,
      NBSCFY_ratio_data: {},

      WXSCFY_detail_data: {},
      isWXSCFYButtonLoading: false,
      WXSCFY_detail_show: false, //外协生产资金详情弹框显示隐藏
      businessCostInfo: {}, //商务结算调整
      isBusinessCostShow: false,
      createdDate: [],
      ownerUnitList: [],
      // allUserList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      initiatorUserList: [], // 所有人员数组
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
      CompanyMembers: state => state.business.CompanyMembers,
    }),
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getType()
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.query && val.query.id) {
          const id = JSON.parse(val.query.id)
          if (id) {
            this.detail({ id })
            // 获取当前页面的 URL
            let currentUrl = window.location.href
            // 清除查询参数部分
            let cleanUrl = currentUrl.split('?')[0]
            // 修改浏览器的历史记录，但不会触发页面刷新
            window.history.replaceState({}, document.title, cleanUrl)
          }
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    sortChange(column) {
      this.tableData = []
      if (column) {
        const orderMapping = {
          createdDate: 'created_date',
          businessStartDate: 'business_start_date',
          principalUserName: 'principal_user_id', // principalUserId
          businessType: 'business_Type',
          status: 'status',
        }

        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }

        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]

          if (this.createdDate && this.$route.query.createdDate != []) {
            this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
            this.parameter.endDate = new Date(this.createdDate[1]).getTime()
          }
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.loading = true
        this.$api.businessManage
          .getAllBusinessManageList(this.parameter)
          .then(res => {
            this.loading = false
            if (!res.data) {
              this.tableData = []
              this.parameter.total = 0
            } else {
              this.tableData = res.data.records
              this.parameter.total = res.data.total
            }
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    // 结算调整
    isBusinessCostFn(row) {
      this.businessCostInfo = row.deepClone()
      this.isBusinessCostShow = true
    },
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    tableRowClassName({ row, index }) {
      if (row.status === 0) {
        return ''
      } else if (row.status === 1) {
        return ''
      } else if (row.status === 21) {
        return 'notGo'
      } else if (row.status === 3) {
        return ''
      } else if (row.status === 5) {
        return 'turnOver'
      } else if (row.status === 6) {
        return 'estaBlish'
      } else if (row.status === 7) {
        return 'end'
      } else if (row.status === 8) {
        return 'beDefeated'
      } else if (row.status === 9) {
        return 'suspend'
      } else if (row.status === 10) {
        return 'finality'
      }
      return ''
    },
    // 获取所有商务负责人
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res?.data.deepClone()
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    delFn(id) {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          this.$api.businessManage
            .deleteBusiness(id)
            .then(res => {
              this.loading = false
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getData() {
      if (this.createdDate && this.$route.query.createdDate != []) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }

      this.loading = true
      this.$api.businessManage
        .getAllBusinessManageList(this.parameter)
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.form = {}
      this.showEditDialog = true
    },
    shenhe(row) {
      this.form = row
      this.showEditDialog = true
    },
    detail(row) {
      this.form = row
      this.showEditDialog = true
    },
    edit(row) {
      this.form = row
      this.showEditDialog2 = true
    },
    re() {
      this.getData()
      this.showEditDialog = false
      this.showEditDialog2 = false
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.userInfo.pageSizeLog,
        businessTypeList: null,
        statusList: null,
        principalUserId: null,
        businessName: null,
        projectSourceList: null,
        beginDate: null,
        endDate: null,
        total: 0,
      }
      this.createdDate = []
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    exporting() {
      this.$api.businessManage
        .allBusinessManageListExpor(this.parameter)
        .then(res => {
          const date = new Date()
          const year = date.getFullYear().toString()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const timestamp = year + month + day
          const fileName = '商务数据明细' + timestamp + '.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }

          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {})
    },

    // 查看商务支出信息
    cost(scope) {
      this.costDialog_data = {
        businessName: null,
        principalUserName: null,
        selectName: null,
        selectRole: null,
        costDetail: {},
        leftTableData: [],
        rightTableData: [],
      }
      this.isCostDialogLoading = true
      this.costDialog_data.businessName = scope.businessName
      this.costDialog_data.businessManageId = scope.id
      this.costDialog_data.principalUserName = scope.principalUserName
      this.costDialog_data.principalUserId = scope.principalUserId
      this.getLeftData()
      this.costDialog_show = true
      this.isCostDialogLoading = false
    },

    // 获取左侧商务报销列表数据
    async getLeftData() {
      this.costDialog_data.leftTableData = []
      this.leftCostLoading = true
      this.leftParams.businessManageId = this.costDialog_data.businessManageId
      const { data: res } = await this.$api.businessTally.listGroupBusinessOrStaff(this.leftParams)
      this.leftCostLoading = false
      if (res?.records) {
        this.leftParams.total = res.total
        this.costDialog_data.leftTableData = res.records
      }
    },

    selectUserCost(value, index) {
      this.rightCostLoading = true
      this.highlightedCell = { rowIndex: index }
      this.costDialog_data.selectId = value.targetId
      this.costDialog_data.selectName = value.targetName
      this.costDialog_data.selectRole = value.role
      this.getRightData()
    },

    async getRightData() {
      this.rightCostLoading = true
      this.costDialog_data.rightTableData = []
      this.rightParams.businessManageId = this.costDialog_data.businessManageId
      this.rightParams.targetId = this.costDialog_data.selectId
      const { data: res } = await this.$api.businessTally.listToStaff(this.rightParams)
      this.rightCostLoading = false
      if (res?.records) {
        this.rightParams.total = res.total
        this.costDialog_data.rightTableData = res.records
      }
    },

    // 左侧分页，页大小改变
    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      this.getLeftData()
    },
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      this.getLeftData()
    },

    handleRightSizeChange(val) {
      this.rightParams.pageSize = val
      this.rightParams.pageNow = 1
      this.getRightData()
    },
    handleRightCurrentChange(val) {
      this.rightParams.pageNow = val
      this.getRightData()
    },

    // 打开报销详情信息对话框
    async showCostDetail(val) {
      this.rightDetailLoading = true
      this.costDialog_data.costDetail = {}
      this.costDialog_data.moduleType = val.moduleType
      if (val.moduleType == 'BUSINESS_EXPENSE') {
        const { data: res } = await this.$api.businessExpense.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      } else if (val.moduleType == 'BUSINESS_REGISTRATION_EXPENDITURE') {
        const { data: res } = await this.$api.register.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      }
      this.costDialog_data.costDetail.detailList.forEach(item => {
        item.expenseFileList.forEach(v => {
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        })
      })
      this.costDetailDialog_show = true
    },

    handleCloseCostDetail() {
      this.costDetailDialog_show = false
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    setCellClass({ rowIndex }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.rowIndex === rowIndex
      return isHighlighted ? ['highlight'] : []
    },

    closeDialog() {
      this.highlightedCell = ''
      this.costDialog_show = false
    },

    computation(row) {
      this.computation_data = {}
      this.$api.businessManage
        .selectAdjustmentDetailByBusinessId(row.id)
        .then(res => {
          if (res.data) {
            this.computation_data = res.data
            this.isComputationShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    showNBSCFY() {
      this.isNBSCFYButtonLoading = true
      this.NBSCFY_ratio_data = {}
      this.$api.productionCostRatioLog
        .productionCostRatioLogByBusiness({ businessId: this.computation_data.id })
        .then(res => {
          this.isNBSCFYButtonLoading = false
          this.NBSCFY_ratio_data = res.data
          this.NBSCFY_ratio_show = true
        })
        .catch(err => {
          this.isNBSCFYButtonLoading = false
          console.log(err)
        })
    },
    showWXSCFY() {
      this.isWXSCFYButtonLoading = true
      this.WXSCFY_detail_data = {}
      this.$api.productionCostRatioLog
        .projectOutsourceLogByBusiness({ businessId: this.computation_data.id })
        .then(res => {
          this.isWXSCFYButtonLoading = false
          this.WXSCFY_detail_data = res.data
          this.WXSCFY_detail_show = true
        })
        .catch(err => {
          this.isWXSCFYButtonLoading = false
          console.log(err)
        })
    },
    getType() {
      this.$api.dict
        .listSysDictData('ALL_BUSINESSMANAGE_TYPE', true)
        .then(res => {
          this.dictData.status = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_MODULE_TYPE', true)
        .then(res => {
          this.dictData.moduleType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_STAFF_ROLE', true)
        .then(res => {
          this.dictData.businessStaffRole = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.businessManage
        .getAllBusinessManageList({ pageSize: 10000 })
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.businessList = []
          } else {
            this.businessList = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.$api.partnerCompany
        .getPartnerCompanyDataList({ pageSize: 10000 })
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.ownerUnitList = []
          } else {
            this.ownerUnitList = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.user
        .staffAllList({ pageSize: 10000 })
        .then(res => {
          this.initiatorUserList = res.data || []
        })
        .catch(err => {
          console.log(err)
        })
      if (this.allUserList.length <= 0) {
        this.getSelectedList()
      } else {
        this.selectedList = this.allUserList
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.el-table .notGo {
  //审批不通过
  background: #e8ebc0;
}
/deep/.el-table .pass {
  //审批通过
  background: rgb(191, 243, 198);
}
/deep/.el-table .turnOver {
  //已移交计划经营部
  background: #d4f5fd;
}
/deep/.el-table .estaBlish {
  //已创建项目
  background: #d3dffc;
}
/deep/.el-table .end {
  //项目完结
  background: #c4fcc7;
}
/deep/.el-table .beDefeated {
  //商务失败
  background: #e0dfdf;
}
/deep/.el-table .suspend {
  //商务暂停
  background: #cde7f7;
}
/deep/.el-table .finality {
  //商务终结
  background: #e08477c0;
  // color: #292929;
}
// 移除hover时高亮效果
/deep/.el-table__body .el-table__row.hover-row td {
  background-color: transparent;
}
/deep/.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
/deep/.el-table__fixed-right tbody tr:hover > td {
  background-color: transparent !important;
}

#costDialog {
  /deep/ .el-table th {
    background: #ddeeff;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }
  .cost-dialog-title {
    margin-bottom: 10px;
  }
}

.empty-detail {
  height: 444px;
  color: grey;
  font-size: 14px;
  text-align: center;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: rgb(241, 212, 158);
}

/deep/.select-item {
  background: rgb(241, 212, 158);
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}

.below-content {
  display: flex;
  justify-content: space-between;
}
/deep/.height-line {
  color: red;
  font-weight: 700;
}
.colorExplain {
  color: black;
  font-size: 14px;
  span {
    margin-right: 25px;
    i {
      display: inline-block;
      width: 25px;
      height: 10px;
    }
  }
}
/deep/.projectBGC {
  //背景色
  background-color: #d0f6fd !important;
  border: 1px solid #ddd !important;
}
/deep/ .more {
  background-color: #aacdee;
  color: #fff;
  padding: 5px 5px;
  margin-left: 8px;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Miantan_Bgc {
  background-color: #6ace61c2;
  color: #fff;
  padding: 5px 5px;
}
.text_RESET_PASSWORD_Bgc {
  background-color: #61becec2;
  color: #fff;
  padding: 5px 5px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
/deep/.my-label {
  width: 20%;
}
/deep/.my-content {
  width: 20%;
}
.mainList .mainList_operation .mainList_operation_search {
  padding-left: 10px;
  .search_center {
    height: auto;
  }
}
/deep/.tags-select-input {
  height: 40px;
  width: 180px !important;
}
.tags-select-input /deep/ .el-select__tags {
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: nowrap;
}
.tags-select-input /deep/ .el-select__tags-text {
  display: inline-block;
  max-width: 55px;
  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
}
.tags-select-input /deep/ .el-tag_close.el-icon-close {
  top: -5px;
  right: -4px;
}
</style>
